import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/cli-windows",
  "date": "17th January 2022",
  "title": "Windows Support for Skpr CLI",
  "wip": true,
  "draft": true,
  "summary": "Skpr recently shipping CLI support for Windows devices!",
  "author": "Nick Schuch",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "windows"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We are pleased to announce the release of Windows support for the Skpr CLI!`}</p>
    <p>{`As of release `}<a parentName="p" {...{
        "href": "https://github.com/skpr/cli/releases/tag/v0.15.0"
      }}>{`v0.15.0`}</a>{` developers are now
able to download and install a Windows native Skpr command-line client.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      